import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/promise";
import "whatwg-fetch";
import "custom-event-polyfill";
export function init() {
  // Source: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }
  if (!Element.prototype.closest) {
    Element.prototype.closest = function closest(s) {
      var el = this;
      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

  /**
   * window.requestIdleCallback()
   * version 0.0.0
   * Browser Compatibility:
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback#browser_compatibility
   *
   * Source: https://github.com/behnammodi/polyfill/blob/master/window.polyfill.js
   */
  if (!window.requestIdleCallback) {
    window.requestIdleCallback = function (callback, options) {
      options = options || {};
      var relaxation = 1;
      var timeout = options.timeout || relaxation;
      var start = performance.now();
      return setTimeout(function () {
        callback({
          get didTimeout() {
            return options.timeout ? false : performance.now() - start - relaxation > timeout;
          },
          timeRemaining: function timeRemaining() {
            return Math.max(0, relaxation + (performance.now() - start));
          }
        });
      }, relaxation);
    };
  }
}
init();